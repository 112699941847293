import React from 'react';
import Layout from '../components/layout';
import MyNavbar from '../components/navbar/navbar';
import Footer from '../components/footer';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Breadcrumb from "../components/breadcrumb";
import Sidebar from '../components/sidebar';
import Cta from "../components/cta";

import main_img from "../images/pallservice/pallservice_main.jpg";
import main_2_img from "../images/pallservice/pallservice_1.jpg";
import img_1 from '../images/pallservice/pallservice_2.jpg';
import img_2 from '../images/pallservice/pallservice_3.jpg';
import img_3 from '../images/pallservice/pallservice_4.jpg';
import img_4 from '../images/pallservice/pallservice_5.jpg';
import img_5 from '../images/pallservice/pallservice_6.jpg';
import favicon from '../images/favicon.ico';

function Plallservice() {
    return (
        <Layout>
            <Helmet>
                <title>Plallservice i Södertälje - www.danasplat.se</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Vi erbjuder pallservice både för standardpall och kundanpassad specialpall. Vi erbjuder pall kvalitet, pall med hållbarhet och kostnadseffektivt" />
                <meta name="keywords" content="Plallservice, palltillverkning" />
                <meta property="og:title" content="Plallservice - Danas Plåt och Smide AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://danasplat.se/plallservice"/>
                <link rel="cannonical" href="https://danasplat.se/plallservice"/>
            </Helmet>

            <MyNavbar active_page="" />
            <section>
                <div className="h-60 md:h-96 w-screen bg-pallservice bg-center bg-no-repeat bg-cover">
                    <div className="h-60 md:h-96 w-screen  bg-opacity-50 bg-black flex justify-center items-center ">
                        <h1 className="text-4xl sm:text-5xl md:text-7xl font-extrabold text-center text-white">Pallservice</h1>
                    </div>
                </div>
                <Breadcrumb page="Pallservice"/>
            </section>


            <section>
                <div className="container mx-auto px-4 pt-24">
                    <div className="grid lg:grid-cols-2 gap-24 lg:gap-10">
                        <div className="bg-white">
                            <div className="w-full h-full flex flex-col justify-center">
                                <h2 className="text-3xl md:text-4xl lg:text-5xl font-extrabold text-left text-green-900">Pallservice för både standardpall och specialpall</h2>
                                <p className="lg:text-xl text-gray-600 mt-12">En pall är grunden för all godshantering och har ett brett användningsområde för all hantering av gods med gaffeltruck. Vi på Danas Plåt och Smide AB har lång erfarenhet av att tillverka och hantera pallar och ser till att ni får det hjälp just ni behöver i rätt tid och till ett förmånligt pris.</p>
                                <p className="lg:text-xl text-gray-600 mt-6">Vi erbjuder pallservice både för standardpall och kundanpassad specialpall. Vi ser till att i samtliga produktionssteg erbjuda kvalitet, hållbarhet och kostnadseffektivt. </p>
                                <p className="lg:text-xl text-gray-600 mt-6">Vi på Danas plåt och Simde AB har stora lager och utför transporter med egna fordon. Vi sköter även logistik åt kunder som önskar det. Allt för att vi vill att du som kund finner en trygg och pålitlig partner för att sköta er pallservice.</p>
                                <Link to="/kontakt" className="block mt-12">
                                    <span className="bg-red-700 shadow-xl text-white text-md md:text-xl py-4 px-6 rounded hover:bg-red-800">Kontakta oss</span>
                                </Link>
                            </div>
                        </div>

                        <div className="w-full h-full">
                            <div className="w-full h-full bg-image-square bg-no-repeat bg-custome bg-right-bottom">
                                <div className="w-full h-full bg-image-circel bg-no-repeat bg-custome bg-left-top p-4 lg:p-8">
                                    <img alt="standardpall och specialpall" className="w-full rounded-tl-3xl rounded-br-3xl rounded-tr-md rounded-bl-md  shadow-xl object-cover h-full object-center block" src={main_img} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container mx-auto px-4 py-24">
                    <hr/>
                </div>
            </section>

            <section>
                <div className="container mx-auto px-4">
                    <div className="grid lg:grid-cols-2 gap-24 lg:gap-10">
                        <div className="w-full h-full">
                            <div className="w-full h-full bg-image-square bg-no-repeat bg-custome bg-right-bottom">
                                <div className="w-full h-full bg-image-circel bg-no-repeat bg-custome bg-left-top p-4 lg:p-8">
                                    <img alt="Pallservice" className="w-full rounded-tl-3xl rounded-br-3xl rounded-tr-md rounded-bl-md  shadow-xl object-cover h-full object-center block" src={main_2_img} />
                                </div>
                            </div>
                        </div>

                        <div className="bg-white">
                            <div className="w-full h-full flex flex-col justify-center">
                                <h2 className="text-3xl md:text-4xl lg:text-5xl font-extrabold text-left text-green-900">Lastpallar med specialmått</h2>
                                <p className="lg:text-xl text-gray-600 mt-12">Vi erbjuder även tillverkning av lastpallar med specialmått. Alla våra pallar är noggrant tillverkade och enligt kundens krav.</p>
                                <p className="lg:text-xl text-gray-600 mt-6">Vi är experter på pallservice och allt som rör hantering kring tillverkning, reparation och lagerhållning av lastpallar.</p>
                                <Link to="/kontakt" className="block mt-12">
                                    <span className="shadow-xl border border-red-700 text-red-700 text-md md:text-xl py-4 px-6 rounded hover:bg-red-800 hover:text-white">Kontakta oss</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container mx-auto px-4 pt-24 pb-32">
                    <hr/>
                </div>
            </section>

            <section>
                <div className="container mx-auto px-4 pb-32">
                    <div className="grid grid-cols-12 gap-10">
                        <div className="col-span-full lg:col-span-3">
                            <Sidebar />
                        </div>
                        <div className="col-span-full lg:col-span-9">
                            <div className="w-full grid lg:grid-cols-2 gap-10">
                                <img alt="plåt pall" className="w-full rounded-md shadow-md object-cover h-full object-center block" src={img_1} />
                                <img alt="tillverka stålpall" className="w-full rounded-md shadow-md object-cover h-full object-center block" src={img_2} />
                                <img alt="Industri pallservice" className="w-full rounded-md shadow-md object-cover h-full object-center block" src={img_3} />
                                <img alt="experter på pallservice" className="w-full rounded-md shadow-md object-cover h-full object-center block" src={img_4} />
                                <img alt="reparation av lastpallar" className="w-full rounded-md shadow-md object-cover h-full object-center block" src={img_5} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Cta />
            <Footer />
        </Layout>
    );
}
export default Plallservice;